<template>
  <div v-if="ds!=null">
    <organization-chart :datasource="ds" id="customId" class="custom-orgchart-container" pan>
      <template slot-scope="{ nodeData }">
        <!-- Customizing the internal structure of each node -->
        <div class="custom-node">
          <div class="custom-title"><span>{{ nodeData.name }}</span></div>
          <div class="custom-details">
            <b-link v-if="nodeData.title==='Agent' || nodeData.title==='Manager'"
                    @click="navigateToUser(nodeData.id)"
                    style="font-weight: bold;font-size: 10px">{{ nodeData.title }}</b-link>
            <span v-else style="font-weight: bold;font-size: 10px">{{ nodeData.title }}</span>
          </div>
        </div>
      </template>
    </organization-chart>

  </div>
  <div v-else class="spinner-container">
    <div class="loading-text">Loading...</div>
  </div>
</template>

<script>
import Vue from 'vue';
import OrganizationChart from 'vue-organization-chart';
import 'vue-organization-chart/dist/orgchart.css';
import { mapActions } from 'vuex';

export default {
  components: {
    OrganizationChart
  },
  created() {
    this.loadData();
  },
  data() {
    return {

      ds: null
    };
  },
  methods: {
    ...mapActions('businessesUnits', ['getHierarchy']),
    navigateToUser(id) {
      this.$router.push('/configurations/users?userId='+id+'&tab=0');
    },
    loadData() {
      this.getHierarchy()
          .then((response) => {
            this.ds = response;
          });
    }
  }
};
</script>
<style scoped>
.org-node {
  border: 2px solid #ccc;
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 5px;
  text-align: center; /* Ensure text is centered */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.org-node .title {
  color: #2c3e50;
  font-weight: bold;
  margin-bottom: 5px;
  background-color: transparent!important;
}

.org-node .name {
  color: #d0172d;
}

.org-node .children {
  margin-top: 10px;
}

.orgchart-container {
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: transparent!important;
  border:0
}


.spinner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  text-align: center;
}

.loading-text {
  margin-top: 16px;
  font-size: 1.2rem;
}

.org-node {
  border: 2px solid #ccc;
  background-color: #f9f9f9;
  padding: 20px; /* Increased padding for more internal space */
  border-radius: 5px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0; /* Add vertical space between nodes */
}

.org-node .title {
  color: #2c3e50;
  font-weight: bold;
  margin-bottom: 5px;
  background-color: transparent!important;
}

.org-node .name {
  color: #d0172d;
}

.org-node .children {
  margin-top: 10px;
}

.orgchart-container {
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: transparent!important;
  border: 0;
}

.orgchart #customId {
  box-sizing: border-box;
  display: inline-block;
  min-height: 202px;
  min-width: 202px;
  padding: 20px;
  margin: 50px 0; /* Add margin to create more space between nodes */
  background-image: linear-gradient(45deg, #f9f9f9 25%, transparent 25%, transparent 75%, #f9f9f9 75%, #f9f9f9), linear-gradient(45deg, #f9f9f9 25%, transparent 25%, transparent 75%, #f9f9f9 75%, #f9f9f9)!important;
  background-size: 10px 10px;
  border: 1px dashed transparent;
}

</style>
